import Vue from 'vue'

import {
  defaultParams,
  defaultCountry,
  headerContactLenses,
  headerSolutionsEyecare,
  headerHelp,
} from '@/utils/constants'

export const state = () => ({
  address: {},
  app: {},
  cdn: '',
  countries: [],
  countryIds: {},
  countryNames: {},
  couponCode: '',
  couponDetails: {},
  couponError: {},
  companyNumber: '',
  communicationConsent: {},
  currencies: [],
  customerServiceEmail: '',
  defaultCountryISO: defaultCountry,
  defaultShippingMethod: '',
  errors: {},
  genesysLivechat: {
    enabled: false,
    deploymentId: '',
  },
  loading: {},
  liftToLegacy: '',
  loqate: {
    enabled: false,
    settings: null,
  },
  loginRedirectTo: '',
  maxQuantity: 36,
  meta: '',
  openingHours: '',
  partnerId: '0',
  phoneNumber: '',
  selectedCurrencyCode: '',
  sessionExpired: false,
  headerContactLenses,
  headerSolutionsEyecare,
  headerHelp,
  socialMediaLinks: [],
  userType: '',
  vatNumber: '',
  cookiesDomain: '',
  transformImages: false,
})

export const getters = {
  mainMenuItems(state) {
    return [state.headerContactLenses, state.headerSolutionsEyecare, state.headerHelp]
  },
}

export const mutations = {
  ADD_LOADING(state, moduleId) {
    if (!state.loading[moduleId]) {
      Vue.set(state, 'loading', {
        ...state.loading,
        [moduleId]: true,
      })
    }
  },
  REMOVE_LOADING(state, moduleId) {
    if (state.loading[moduleId]) {
      const { [moduleId]: toRemove, ...rest } = state.loading
      Vue.set(state, 'loading', rest)
    }
  },
  ADD_ERROR(state, module) {
    if (!state.errors[module.id]) {
      Vue.set(state, 'errors', {
        ...state.errors,
        [module.id]: module.error,
      })
    }
  },
  REMOVE_ERROR(state, moduleId) {
    if (state.errors[moduleId]) {
      const { [moduleId]: toRemove, ...rest } = state.errors
      Vue.set(state, 'errors', rest)
    }
  },
  SET_GLOBAL_OVERLAY(state, overlay) {
    Vue.set(state, 'globalOverlay', overlay)
  },
  SET_CONFIG(state, config) {
    Vue.set(state, 'address', config?.address)
    Vue.set(state, 'app', config?.app)
    Vue.set(state, 'cdn', config?.cdn)
    Vue.set(state, 'currencies', config?.currencies)
    Vue.set(state, 'companyNumber', config?.companyNumber)
    Vue.set(state, 'communicationConsent', config?.app.communicationConsent)
    Vue.set(state, 'customerServiceEmail', config?.customerServiceEmail)
    Vue.set(state, 'defaultShippingMethod', config?.defaultShippingMethod)
    Vue.set(state, 'selectedCurrencyCode', config?.defaultCurrency)
    Vue.set(state, 'socialMediaLinks', config?.socialMediaLinks)
    Vue.set(state, 'defaultCountryISO', config?.defaultCountry || defaultCountry)
    Vue.set(state, 'openingHours', config?.openingHours)
    Vue.set(state, 'phoneNumber', config?.phoneNumber)
    Vue.set(state, 'vatNumber', config?.vat)
    Vue.set(state, 'maxQuantity', config?.maxBasketQuantity)
    Vue.set(state, 'meta', config?.meta)
    Vue.set(state, 'partnerId', config?.partnerId || '0')
    Vue.set(state, 'loqate', config?.loqate)
    Vue.set(state, 'genesysLivechat', config?.genesysLivechat)
    Vue.set(state, 'transformImages', config?.app?.transformImages?.enabled)
    Vue.set(
      state.headerContactLenses.subMenus[1],
      'items',
      config.bestsellers.map((bestseller) => {
        return { title: bestseller.attributes.name, link: `/${bestseller.type}/${bestseller.id}` }
      }),
    )
  },
  SET_COUNTRIES(state, countries) {
    const countriesList = []
    const countryIds = {}
    const countryNames = {}

    countries.forEach((country) => {
      countriesList.push({ value: country.iso.iso2, label: country.name })
      countryIds[country.iso.iso2] = country.id
      countryNames[country.id] = country.name
    })

    Vue.set(state, 'countries', countriesList)
    Vue.set(state, 'countryIds', countryIds)
    Vue.set(state, 'countryNames', countryNames)
  },
  SET_SELECTED_CURRENCY_CODE(state, selectedCurrencyCode) {
    Vue.set(state, 'selectedCurrencyCode', selectedCurrencyCode)
  },
  SET_COUPON_CODE(state, couponCode) {
    Vue.set(state, 'couponCode', couponCode)
    Vue.set(state, 'couponDetails', {})
    Vue.set(state, 'couponError', {})
  },
  REMOVE_COUPON_CODE(state) {
    Vue.set(state, 'couponCode', '')
    Vue.set(state, 'couponDetails', {})
    Vue.set(state, 'couponError', {})
  },
  SET_COUPON_DETAILS(state, couponDetails) {
    Vue.set(state, 'couponCode', couponDetails?.code)
    Vue.set(state, 'couponDetails', { ...couponDetails })
    Vue.set(state, 'couponError', {})
  },
  SET_COUPON_ERROR(state, couponError) {
    Vue.set(state, 'couponDetails', {})
    Vue.set(state, 'couponError', { ...couponError })
  },
  SET_SPLIT_AUTH_KEY(state, splitAuthKey) {
    Vue.set(state, 'splitAuthKey', splitAuthKey)
  },
  SET_LIFT_TO_LEGACY(state, liftToLegacy) {
    Vue.set(state, 'liftToLegacy', liftToLegacy)
  },
  SET_USER_TYPE(state, userType) {
    Vue.set(state, 'userType', userType)
  },
  SET_LOGIN_REDIRECT_TO(state, loginRedirectTo) {
    if (loginRedirectTo !== '/login') {
      Vue.set(state, 'loginRedirectTo', loginRedirectTo)
    }
  },
  SET_SESSION_EXPIRED(state, sessionExpired) {
    Vue.set(state, 'sessionExpired', sessionExpired)
  },
  CLEAR_ERRORS(state) {
    Vue.set(state, 'errors', [])
  },
  CLEAR_LOADING(state) {
    Vue.set(state, 'loading', [])
  },
  SET_COOKIES_DOMAIN(state, domain) {
    Vue.set(state, 'cookiesDomain', domain)
  },
}

export const actions = {
  async nuxtServerInit({ dispatch, commit }, { req }) {
    if (req?.headers?.host) {
      const hostWithoutPort = req.headers.host.split(':')[0]
      commit('SET_COOKIES_DOMAIN', hostWithoutPort)
    }

    const orderId = this.$cookies.get('orderId')
    const { loggedIn } = this.$auth

    const hasLegacyCookie = this.$cookies.get('lsv') !== undefined
    const hasSpaCookie = this.$cookies.get('auth._token.local') !== undefined
    const userType = hasLegacyCookie || hasSpaCookie ? 'returning' : 'new'

    commit('SET_USER_TYPE', userType)

    if (orderId && loggedIn) {
      await dispatch('order/getOrder', orderId)
      await dispatch('addresses/getAllAddresses')
      await dispatch('order/getShippingOptions')
    }

    commit('SET_SPLIT_AUTH_KEY', process.env.SPLIT_AUTH_KEY_CLIENT)
    await Promise.all([dispatch('getConfig'), dispatch('getCountries')])
  },
  async getConfig({ commit }) {
    commit('ADD_LOADING', 'config')

    try {
      const localeAPI = this.$i18n.localeProperties.api
      const response = await this.$axios.get(`${localeAPI}/configuration`, { params: defaultParams, useCache: true })
      const { data: config } = response.data

      commit('SET_CONFIG', config)
    } catch (error) {
      commit('ADD_ERROR', { id: 'config', error })
    }

    commit('REMOVE_LOADING', 'config')
  },
  async getCountries({ commit }) {
    commit('ADD_LOADING', 'countries')

    try {
      const localeAPI = this.$i18n.localeProperties.api
      const response = await this.$axios.get(`${localeAPI}/country`, { params: defaultParams, useCache: true })
      const { data: countries } = response.data

      commit('SET_COUNTRIES', countries)
    } catch (error) {
      commit('ADD_ERROR', { id: 'countries', error })
    }

    commit('REMOVE_LOADING', 'countries')
  },
  setSelectedCurrency({ commit }, currencyCode) {
    commit('SET_SELECTED_CURRENCY_CODE', currencyCode)
  },
  setCouponCode({ commit }, couponCode) {
    commit('SET_COUPON_CODE', couponCode)

    if (couponCode) {
      this.$cookies.set('couponCode', couponCode)
    }
  },
  async removeCouponCodeAndUpdateOrderOrBasket({ dispatch, rootState }) {
    const { orderId } = rootState.order.order
    dispatch('removeCouponCode')
    if (orderId && this.$auth.loggedIn) {
      await dispatch('order/updateOrder', { orderContext: 'store/index removeCouponCodeAndUpdateOrderOrBasket' })
      await dispatch('order/getShippingOptions')
    } else {
      await dispatch('basket/getDiscountedBasket')
    }
  },
  removeCouponCode({ commit }) {
    commit('REMOVE_COUPON_CODE')
    this.$cookies.remove('couponCode')
  },
  async applyCouponCode({ dispatch, rootState }, couponCode) {
    const { orderId } = rootState.order.order

    if (couponCode) {
      dispatch('setCouponCode', couponCode)
    }

    if (orderId && this.$auth.loggedIn) {
      await dispatch('order/updateOrder', { orderContext: 'store/index applyCouponCode' })
      await dispatch('order/getShippingOptions')
    } else {
      await dispatch('basket/getDiscountedBasket')
    }
  },
  async silentLogOut({ dispatch }) {
    await this.$auth.logout('local')
    dispatch('order/resetOrder')
  },
  async logOut({ dispatch }) {
    await this.$auth.logout('local')
    this.$auth.$storage.removeCookie('rememberMe')
    dispatch('clearOrder')

    this.$router.push('/')
  },
  clearOrder({ dispatch, commit }) {
    dispatch('basket/emptyBasket')
    commit('REMOVE_COUPON_CODE')
    this.$cookies.remove('couponCode')
    dispatch('order/resetOrder')
  },
  liftToLegacy({ commit }, target) {
    commit('SET_LIFT_TO_LEGACY', target)
  },
}
