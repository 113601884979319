export function decodeHTML(html) {
  return html.replace(/&#(\d+);/g, (_match, dec) => String.fromCharCode(dec))
}

export function getDeserializedCategoryProducts(products) {
  return products.map((product) => {
    const { name, price, discountPrice, review, productId, images } = product
    const [image] = images

    return {
      name,
      image: image || null,
      price,
      discountPrice,
      reviewStars: Math.round(review.average),
      reviewsTotal: review.count,
      link: `/product/${productId}`,
    }
  })
}

export function getDeserializedProduct(product, cdn) {
  const deserializedProduct = {
    id: product.productId,
    meta: product.meta,
    code: product.productCode,
    name: product.name,
    brand: product.brand,
    duration: product.duration,
    durationDays: product.durationDays || 0,
    fullPrice: product.price,
    discountedPrice: product.discountPrice || null,
    pricePerUnit: product.pricePerUnit || null,
    reviewAverage: product.review.average || 0,
    reviewStars: Math.round(product.review.average) || 0,
    reviewsTotal: product.review.count || 0,
    images: product.images,
    content: product.content || [],
    options: product.options || [],
    showAuthor: !!product?.features?.showAuthor,
    disabled: product.disabled,
    alternativeProduct: product.alternativeProduct,
    catName: product.catName,
    catId: product.catId,
    gtin13: product.gtin13 || '',
    packSize: product.packSize,
    breadcrumb: product.breadcrumb,
    modality: product.modality || '',
    productType: product.productType || '',
    upgradePath: product.upgradePath || null,
  }

  deserializedProduct.options.forEach((option) => {
    if (option.type === 'colour') {
      option.items.forEach((item) => {
        const colourNameSlug = item.label.toLowerCase().replace(/\s+/g, '-').trim()
        item.icon = `${cdn}/27/colours/${deserializedProduct.code.toLowerCase()}-${colourNameSlug}.jpg`
      })
    }
  })

  return deserializedProduct
}

export function getDeserializedCategories(homepage, bestSellersId) {
  return homepage.categories.map((category) => {
    const { products, name, id } = category
    const deserializedProducts = getDeserializedCategoryProducts(products)
    const link = id !== 0 ? `/category/${id}` : `/brand/${bestSellersId}`

    return {
      name,
      link,
      products: deserializedProducts,
    }
  })
}

export function getDeserializedTiles(homepage) {
  return homepage.tiles.map((tile) => {
    const { location, content } = tile
    const deserializedContent = content.map((contentItem) => {
      const { title, type, subtitle, ctaLink, ctaText, image, colour = '#fff' } = contentItem

      return {
        title,
        type,
        subtitle,
        ctaLink,
        ctaText,
        images: {
          mobile: image.path,
          tablet: image.path,
          desktop: image.path,
          colour,
          alt: image.alt,
        },
      }
    })

    return {
      location,
      content: deserializedContent,
    }
  })
}
