
import { mapGetters, mapState } from 'vuex'

import AccountLogin from '@/components/AccountLogin'
import SearchBar from '@/components/SearchBar'
import MegaMenu from '@/components/MegaMenu'
import BurgerMenu from '@/components/BurgerMenu'

import LenstoreLogo from '@/static/svg/lenstore-logo.svg'
import ReorderIcon from '@/static/svg/nav/reorder-icon.svg'
import AccountIcon from '@/static/svg/nav/account-icon.svg'
import BasketIcon from '@/static/svg/nav/basket-icon.svg'
import SearchIcon from '@/static/svg/nav/search-icon.svg'
import CloseIcon from '@/static/svg/nav/close-icon.svg'

export default {
  components: {
    AccountLogin,
    SearchBar,
    LenstoreLogo,
    ReorderIcon,
    AccountIcon,
    BasketIcon,
    SearchIcon,
    CloseIcon,
    MegaMenu,
    BurgerMenu,
  },
  props: {
    enclosed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLogoClicked: false,
      isSearchBarOpen: false,
      searchEvent: null,
    }
  },
  computed: {
    ...mapGetters('basket', ['totalQuantity', 'totalPrice']),
    ...mapGetters(['mainMenuItems']),
    ...mapState(['search', 'phoneNumber', 'basket', 'couponCode']),
    cappedTotalQuantity() {
      return this.totalQuantity < 100 ? this.totalQuantity : 99
    },
    loggedIn() {
      return this.$auth.loggedIn
    },
  },
  watch: {
    $route() {
      this.isSearchBarOpen = false
    },
  },
  methods: {
    toggleSearchBar() {
      this.isSearchBarOpen = !this.isSearchBarOpen
    },
    closeSearchBar() {
      this.isSearchBarOpen = false
    },
    basketClicked() {
      this.isSearchBarOpen = false
    },
    getAutocompleteResults(query) {
      clearTimeout(this.searchEvent)
      this.searchEvent = setTimeout(() => {
        this.$store.dispatch('search/getAutocompleteResults', query)
      }, 750)
    },
    logoClicked(event) {
      if (this.enclosed) {
        event.preventDefault()
        this.$emit('show-leave-dialog')
      }
    },
    goToAccount() {
      const path = '/account'

      if (this.$auth.loggedIn) {
        this.$router.push(this.$getLink(path))
      } else {
        this.$store.commit('SET_LOGIN_REDIRECT_TO', path)
        this.$router.push(this.$getLink('/login'))
      }
    },
  },
}
