import Vue from 'vue'

import { defaultParams } from '@/utils/constants'
import { getDeserializedCategoryProducts } from '@/utils/deserialization'

export const state = () => ({
  results: 0,
  page: 0,
  products: [],
  autocompleteResults: [],
})

export const mutations = {
  RESET_SEARCH_RESULTS(state) {
    Vue.set(state, 'results', 0)
    Vue.set(state, 'page', 0)
    Vue.set(state, 'products', [])
  },
  ADD_MORE_SEARCH_RESULTS(state, results) {
    Vue.set(state, 'results', results.results)
    Vue.set(state, 'products', [...state.products, ...results.products])
  },
  SET_SEARCH_RESULTS_PAGE(state, page) {
    Vue.set(state, 'page', page)
  },
  SET_AUTOCOMPLETE_RESULTS(state, results) {
    Vue.set(state, 'autocompleteResults', results)
  },
}

export const actions = {
  async getSearchResults({ commit, dispatch }, query) {
    commit('RESET_SEARCH_RESULTS')
    await dispatch('loadMoreResults', query)
  },
  async loadMoreResults({ commit, state, rootState, dispatch }, query) {
    query = query || ''
    query = query.replace(/ +/g, '+')
    commit('REMOVE_ERROR', 'search', { root: true })
    commit('ADD_LOADING', 'search', { root: true })

    const { page } = state
    const nextPage = page + 1
    const { couponCode } = rootState
    const params = { page: nextPage, ...(couponCode && { couponCode }), ...defaultParams }

    try {
      const response = await this.$axios.get(`search/${query}`, { params })
      const { data: searchResults } = response.data
      const { products } = searchResults
      const deserializedProducts = getDeserializedCategoryProducts(products)
      const deserializedSearchResults = { ...searchResults, products: deserializedProducts }

      commit('SET_SEARCH_RESULTS_PAGE', nextPage)
      commit('ADD_MORE_SEARCH_RESULTS', deserializedSearchResults)
    } catch (error) {
      if (error.response?.status === 401) {
        await dispatch('silentLogOut', null, { root: true })

        try {
          const response = await this.$axios.get(`search/${query}`, { params })
          const { data: searchResults } = response.data
          const { products } = searchResults
          const deserializedProducts = getDeserializedCategoryProducts(products)
          const deserializedSearchResults = { ...searchResults, products: deserializedProducts }

          commit('SET_SEARCH_RESULTS_PAGE', nextPage)
          commit('ADD_MORE_SEARCH_RESULTS', deserializedSearchResults)
        } catch (error) {
          commit('ADD_ERROR', { id: 'product', error }, { root: true })
        }
      }

      commit('ADD_ERROR', { id: 'product', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'search', { root: true })
  },
  async getAutocompleteResults({ commit }, query) {
    commit('REMOVE_ERROR', 'autocomplete', { root: true })
    commit('ADD_LOADING', 'autocomplete', { root: true })

    try {
      const params = { search: query, ...defaultParams }
      const response = await this.$axios.get('/search/autocomplete', { params })

      const { data: results } = response.data

      commit('SET_AUTOCOMPLETE_RESULTS', results)
    } catch (error) {
      commit('ADD_ERROR', { id: 'autocomplete', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'autocomplete', { root: true })
  },
}
