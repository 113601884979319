
import { mapState } from 'vuex'
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['transformImages']),
  },
}
