import Vue from 'vue'

import { reviewsPerPage, defaultParams } from '@/utils/constants'
import { getDeserializedProduct } from '@/utils/deserialization'

export const state = () => ({
  id: '',
  breadcrumb: [],
  name: '',
  brand: null,
  duration: '',
  durationDays: 0,
  fullPrice: '',
  discountedPrice: '',
  pricePerUnit: null,
  reviewStars: 0,
  reviewAverage: 0,
  reviewsTotal: 0,
  images: [],
  options: [],
  content: [],
  showAuthor: false,
  reviews: [],
  reviewsPage: 1,
  canLoadMoreReviews: true,
  recommendations: [],
  disabled: false,
  alternativeProduct: null,
  packSize: '',
  meta: '',
  gtin13: '',
  modality: '',
  productType: '',
  upgradePath: null,
})

export const mutations = {
  SET_ID(state, id) {
    Vue.set(state, 'id', id)
  },
  SET_PRODUCT(state, product) {
    Vue.set(state, 'breadcrumb', product.breadcrumb)
    Vue.set(state, 'id', product.id)
    Vue.set(state, 'code', product.code)
    Vue.set(state, 'name', product.name)
    Vue.set(state, 'brand', product.brand)
    Vue.set(state, 'durationDays', product.durationDays)
    Vue.set(state, 'packSize', product.packSize)
    Vue.set(state, 'fullPrice', product.fullPrice)
    Vue.set(state, 'discountedPrice', product.discountedPrice)
    Vue.set(state, 'pricePerUnit', product.pricePerUnit)
    Vue.set(state, 'reviewStars', product.reviewStars)
    Vue.set(state, 'reviewAverage', product.reviewAverage)
    Vue.set(state, 'reviewsTotal', product.reviewsTotal)
    Vue.set(state, 'catName', product.catName)
    Vue.set(state, 'images', product.images)
    Vue.set(state, 'options', product.options)
    Vue.set(state, 'content', product.content)
    Vue.set(state, 'showAuthor', product.showAuthor)
    Vue.set(state, 'disabled', product.disabled)
    Vue.set(state, 'gtin13', product.gtin13)
    Vue.set(state, 'modality', product.modality)
    Vue.set(state, 'link', `/product/${product.id}`)
    if (product.alternativeProduct) {
      Vue.set(state, 'alternativeProduct', {
        name: product.alternativeProduct.name,
        link: `/product/${product.alternativeProduct.productId}`,
      })
    }
    Vue.set(state, 'reviews', [])
    Vue.set(state, 'meta', product.meta)
    Vue.set(state, 'canLoadMoreReviews', true)
    Vue.set(state, 'reviewsPage', 1)
    Vue.set(state, 'productType', product.productType)
    Vue.set(state, 'upgradePath', product.upgradePath)
  },
  ADD_REVIEWS(state, reviews) {
    Vue.set(state, 'reviews', [...state.reviews, ...reviews])
    Vue.set(state, 'canLoadMoreReviews', (state.reviewsPage - 1) * reviewsPerPage + reviews.length < state.reviewsTotal)
    Vue.set(state, 'reviewsPage', state.reviewsPage + 1)
  },
  SET_RECOMMENDATIONS(state, recommendations) {
    Vue.set(state, 'recommendations', recommendations)
  },
}

export const actions = {
  setProductId({ commit }, productId) {
    commit('SET_ID', productId)
  },
  async getProduct({ commit, state, rootState, dispatch }) {
    commit('REMOVE_ERROR', 'product', { root: true })
    commit('ADD_LOADING', 'product', { root: true })

    const { couponCode, cdn } = rootState
    const params = { ...(couponCode && { couponCode }), ...defaultParams }

    try {
      const response = await this.$axios.get(`/products/${state.id}`, { params })
      const { data: product } = response.data
      const deserializedProduct = getDeserializedProduct(product, cdn)

      commit('SET_PRODUCT', deserializedProduct)
    } catch (error) {
      if (error.response?.status === 404) {
        this.app.context.error({ statusCode: 404, message: 'Product not found' })
      } else if (error.response?.status === 401) {
        await dispatch('silentLogOut', null, { root: true })

        try {
          const response = await this.$axios.get(`/products/${state.id}`, { params })
          const { data: product } = response.data
          const deserializedProduct = getDeserializedProduct(product, cdn)

          commit('SET_PRODUCT', deserializedProduct)
        } catch (error) {
          commit('ADD_ERROR', { id: 'product', error }, { root: true })
        }
      }

      commit('ADD_ERROR', { id: 'product', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'product', { root: true })
  },
  async getReviews({ commit, state }) {
    commit('REMOVE_ERROR', 'reviews', { root: true })
    commit('ADD_LOADING', 'reviews', { root: true })

    try {
      const params = { page: state.reviewsPage, perPage: reviewsPerPage, ...defaultParams }
      const response = await this.$axios.get(`/reviews/product/${state.id}`, { params })
      const { data: reviews } = response.data

      commit('ADD_REVIEWS', reviews)
    } catch (error) {
      commit('ADD_ERROR', { id: 'reviews', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'reviews', { root: true })
  },
  async getRecommendations({ commit, state, rootState }) {
    commit('REMOVE_ERROR', 'recommendations', { root: true })
    commit('ADD_LOADING', 'recommendations', { root: true })

    try {
      const { couponCode } = rootState
      const response = await this.$axios.get('/products/recommendations', {
        params: { productIds: state.id, ...(couponCode && { couponCode }), ...defaultParams },
      })

      const { data: recommendations } = response.data
      const deserializedRecommendations = recommendations.map((recommendation) => {
        const [image] = recommendation.images

        return {
          ...recommendation,
          image: image || null,
          link: `/product/${recommendation.productId}`,
        }
      })

      commit('SET_RECOMMENDATIONS', deserializedRecommendations)
    } catch (error) {
      commit('ADD_ERROR', { id: 'recommendations', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'recommendations', { root: true })
  },
}
