
import { mapState } from 'vuex'
import {
  termsDocumentId,
  privacyDocumentId,
  footerMenuItems,
  footerMenuItemsDE,
  footerMenuItemsFR,
  footerMenuItemsIT,
  footerMenuItemsES,
} from '@/utils/constants'
import slugifyMixin from '@/mixins/slugify'

import AccordionContainer from '@/components/AccordionContainer'

import FacebookIcon from '@/static/svg/social/global-icn-facebook.svg'
import InstagramIcon from '@/static/svg/social/global-icn-instagram.svg'
import LinkedInIcon from '@/static/svg/social/global-icn-linked-in.svg'
import PinterestIcon from '@/static/svg/social/global-icn-pintrest.svg'
import TwitterIcon from '@/static/svg/social/global-icn-twitter.svg'
import LenstoreHubIcon from '@/static/svg/social/lenstore-hub-icon.svg'
import LenstoreBlogIcon from '@/static/svg/social/lenstore-blog-icon.svg'
import VisaIcon from '@/static/svg/payments/visa-card.svg'
import MasterIcon from '@/static/svg/payments/mastercard-card.svg'
import AmexIcon from '@/static/svg/payments/amex-card.svg'
import PaypalIcon from '@/static/svg/payments/global-icn-payments-pay-pal.svg'
import GBIcon from '@/static/svg/countries/global-flag-gb.svg'
import FranceIcon from '@/static/svg/countries/global-flag-france.svg'
import ItalyIcon from '@/static/svg/countries/global-flag-italy.svg'
import SpainIcon from '@/static/svg/countries/global-flag-spain.svg'
import GermanyIcon from '@/static/svg/countries/global-flag-de.svg'
import PadlockIcon from '@/static/svg/payments/global-icn-securepayment.svg'
import LenstoreLogo from '@/static/svg/lenstore-logo.svg'
import KlarnaIcon from '@/static/svg/payments/klarna-pink-badge.svg'
import ApplePayIcon from '@/static/svg/payments/apple-pay.svg'
import GooglePayIcon from '@/static/svg/payments/google-pay-mark.svg'
import SofortDarkIcon from '@/static/svg/payments/sofort-dark.svg'

export default {
  components: {
    AccordionContainer,
    FacebookIcon,
    InstagramIcon,
    LinkedInIcon,
    PinterestIcon,
    TwitterIcon,
    LenstoreHubIcon,
    LenstoreBlogIcon,
    VisaIcon,
    MasterIcon,
    AmexIcon,
    PaypalIcon,
    GBIcon,
    FranceIcon,
    ItalyIcon,
    SpainIcon,
    GermanyIcon,
    PadlockIcon,
    LenstoreLogo,
    KlarnaIcon,
    ApplePayIcon,
    GooglePayIcon,
    SofortDarkIcon,
  },
  mixins: [slugifyMixin],
  props: {
    enclosed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeAccordion: '',
      footerMenuItems,
      footerMenuItemsDE,
      footerMenuItemsFR,
      footerMenuItemsIT,
      footerMenuItemsES,
      showKlarna: false,
      showApplePay: false,
      showGooglePay: false,
      showSofort: false,
      paymentApplePay: false,
      paymentGooglePay: false,
      paymentSofort: false,
      showKlarnaSplit: false,
      studentDiscountShowInFooterMenu: false,
    }
  },
  computed: {
    ...mapState([
      'openingHours',
      'phoneNumber',
      'customerServiceEmail',
      'companyNumber',
      'vatNumber',
      'socialMediaLinks',
      'app',
    ]),
    callTo() {
      return `tel:${this?.phoneNumber?.replace(/\s+/g, '')}`
    },
    mailTo() {
      return `mailto:${this.customerServiceEmail}`
    },
    loggedIn() {
      return this.$auth.loggedIn
    },
    termsLink() {
      return `/document/${termsDocumentId}`
    },
    privacyLink() {
      return `/document/${privacyDocumentId}`
    },
    footerMenu() {
      const locale = this.$i18n.locale
      if (locale === 'de-DE') return footerMenuItemsDE
      if (locale === 'fr-FR') return footerMenuItemsFR
      if (locale === 'it-IT') return footerMenuItemsIT
      if (locale === 'es-ES') return footerMenuItemsES
      else return footerMenuItems
    },
    storeLocatorEnabled() {
      return this.app?.storeLocator?.enabled
    },
  },
  async mounted() {
    const flags = (await this.$patchboard.flags())?.payments

    const applePayModuleEnabled = flags?.braintree?.applePay?.enabled
    const googlePayModuleEnabled = flags?.braintree?.googlePay?.enabled
    const sofortModuleEnabled = flags?.braintree?.sofort?.enabled

    this.paymentApplePay = await this.$store.dispatch('split/getSplitTreatment', 'paymentApplePay')
    this.paymentGooglePay = await this.$store.dispatch('split/getSplitTreatment', 'paymentGooglePay')
    this.paymentSofort = await this.$store.dispatch('split/getSplitTreatment', 'paymentSofort')
    this.showKlarnaSplit = await this.$store.dispatch('split/getSplitTreatment', 'showKlarnaSplit')
    this.studentDiscountShowInFooterMenu = await this.$store.dispatch(
      'split/getSplitTreatment',
      'studentDiscountShowInFooterMenu',
    )

    if (flags?.braintree?.enabled) {
      this.showApplePay = applePayModuleEnabled && this.paymentApplePay
      this.showGooglePay = googlePayModuleEnabled && this.paymentGooglePay
      this.showSofort = sofortModuleEnabled && this.paymentSofort
    }

    this.showKlarna = this.showKlarnaSplit
  },
  methods: {
    onToggle() {
      this.$emit('toggled')
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    toggleAccordion(clickedAccordion) {
      this.activeAccordion = this.activeAccordion !== clickedAccordion ? clickedAccordion : ''
    },
    logoClicked(event) {
      if (this.enclosed) {
        event.preventDefault()
        this.$emit('show-leave-dialog')
      }
    },
    reorder() {
      const path = '/account'

      if (this.$auth.loggedIn) {
        this.$router.push(this.$getLink(path))
      } else {
        this.$store.commit('SET_LOGIN_REDIRECT_TO', path)
        this.$router.push(this.$getLink('/login'))
      }
    },
    goToAddressBook() {
      const path = '/address-book'

      if (this.$auth.loggedIn) {
        this.$router.push(this.$getLink(path))
      } else {
        this.$store.commit('SET_LOGIN_REDIRECT_TO', path)
        this.$router.push(this.$getLink('/login'))
      }
    },
    login() {
      this.$router.push(this.$getLink('/login'))
    },
    reduceFooterMenu(items) {
      return this.studentDiscountShowInFooterMenu ? items : items.filter((item) => !item.enableSplit)
    },
  },
}
